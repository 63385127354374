import {useState} from "react";
import {baseApi} from "../../api/axios";

import {Link, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function PayFixedProduct({product}) {
    const authStore = useAuthStore()
    const [errors, setErrors] = useState({});
    const [player_id, setPlayerId] = useState('');
    const [player_name, setPlayerName] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState('');
    const redirect = useNavigate()
    const [loadingName, setLoadingName] = useState(false)
    const checkPlayer = async () => {
        setLoadingName(true)
        setPlayerName('')
        const name = await authStore.getPlayerName(product.id, player_id);
       // console.log(name,'NAME IS')
        setPlayerName(name)
        setLoadingName(false)
    }
    const onSubmit = async () => {
        setLoading(true)
        setErrors({})
        if (player_id.length === 0) {
            setErrors({...errors, msg: 'يرجى إدخال رقم اللاعب'})
            return;
        }
        const data = new FormData();
        data.append('player_id', player_id)
        data.append('player_name', player_name)
        data.append('product_id', product.id)
        data.append('note', note)
        try {
            const response = await baseApi.post('/orders', data)
            if(response.data?.user){
                authStore.setUser(response.data?.user)
                localStorage.setItem('user',JSON.stringify(response.data?.user))
            }

            redirect('/orders')
        } catch (e) {
            setErrors({...errors, msg: e.response?.data?.msg})
        }
        setLoading(false)


    }
    return (
        <div className="col-md-8 ">
            <div className="form mx-3">
                <p className={'text-orange'}><i className="fas fa-caret-left text-orange fa-2x"/> {product.name}</p>
                <ul>
                    {/* eslint-disable-next-line array-callback-return */}
                    {Object.entries(errors).map(([key, value], i) => {
                        if (value) {
                            return <li key={i} className={'text-danger'}>
                                {value}
                            </li>
                        }
                    })}
                </ul>
                <div className=" px-2">
                  <div className="d-flex align-items-center gap-2">
                      <input type="text" onChange={(e) => setPlayerId(e.target.value)}  placeholder={product.type==='id'?'ID الحساب':product.type==='account'?'اسم المستخدم /  البريد الإلكتروني':'رقم الهاتف/رابط الصفحة'}
                             className={'input-form my-2'}/>{authStore.checkPlayer && product.is_check  ?
                      <span className={`checkName ${loadingName?'loading':''}`} onClick={checkPlayer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>
                        </span>
                      : null}
                  </div>
                    <input value={player_name} type="text" onChange={(e) => setPlayerName(e.target.value)} placeholder={product.type==='id'?'اسم الحساب':product.type==='account'?'كلمة المرور':'اسم الحساب'}
                           className={'input-form my-2'}/>
                    <textarea onChange={(e) => setNote(e.target.value)} className={'textarea-form my-2'} cols="30"
                              rows="4" placeholder={'ملاحظات'}></textarea>
                    {!loading ? (
                        authStore.user === null ? (
                            <Link to="/login" className="btn d-block btn-sm submit-btn">تسجيل الدخول</Link>
                        ) : (
                            <button onClick={onSubmit} className="btn d-block btn-sm submit-btn">شراء</button>
                        )
                    ) : (
                        <button className="btn d-block btn-sm submit-btn">جاري الطلب</button>
                    )}


                </div>
            </div>

        </div>
    )
}